<template>
  <main class="business-inquiry">
    <content-page-header plan="corporate">
      <common-page-title title="法人向けライセンス" />
      <div class="page-header-explanation">
        <p class="page-header-explanation-text">
          ジッセン!DXのコンテンツを社内研修としてご活用いただけます。
        </p>
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body icon-none="true">
        <section>
          <common-block-title title="法人のお客様へ" sub-title="" />
          <p class="description">
            『ジッセン!DX』は、ITツールの基礎操作から業務に役立つDXノウハウ、スキルを現場の最前線で活躍する講師陣から学べるため、社内研修や勉強会等ですぐにご活用いただけます。<br />
            また、自社専用LMSとして活用することも可能です。ジッセン!DXの教材だけでなく、自社の動画/テスト教材を作成して新入社員研修で活用することも可能です。<br />
            お気軽にお問合せください。
          </p>
        </section>
      </panel-body>
      <i class="business-inquiry-purple-circle"></i>
    </div>
    <div class="content-body mt-0">
      <common-block-title
        class="out-side-content-body-header"
        title="ジッセン!サービスの導入企業事例（一部）"
        sub-title=""
      />
      <div class="corp-bnner-block">
        <panel-body icon-none="true">
          <section>
            <div class="corp-bnner">
              <ul class="corp-bnner-list">
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-01.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-02.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-03.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-04.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-05.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-06.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-07.png" alt="" />
                </li>
                <li class="corp-bnner-list-item">
                  <img src="@/assets/img/corp-bn-08.png" alt="" />
                </li>
              </ul>
            </div>
          </section>
        </panel-body>
        <icon name="bg-dot-circle" class="layout-bg" />
      </div>
    </div>
    <!-- <bottomBtn :label="'導入事例はこちら'" :routeName="''" /> -->
    <div class="content-body mt-0">
      <panel-body>
        <section class="inquiry-content">
          <common-block-title
            title="法人向けライセンスのお問い合わせ"
            sub-title=""
          />
          <form id="inquiryForm" class="inquiry-form">
            <label class="form-function">
              <div class="form-input-label">
                法人名<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="text"
                  placeholder="法人名"
                  v-model="state.companyName"
                />
              </div> </label
            ><label class="form-function">
              <div class="form-input-label">
                担当者名<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="email"
                  placeholder="担当者名"
                  v-model="state.name"
                />
              </div>
            </label>

            <label class="form-function">
              <div class="form-input-label">
                業種<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <el-select placeholder="業種" v-model="state.industriesValue">
                  <el-option
                    v-for="item in state.industries"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </label>

            <label class="form-function items-baseline">
              <div class="form-input-label form-input-label--account">
                希望アカウント数<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="number"
                  placeholder="希望アカウント数"
                  v-model="state.account"
                />
                <div class="form-input-label--explanation">
                  <p>※10アカウント以上よりご相談承ります</p>
                  <p>※ご契約後のアカウント数の増加もご対応可能です。</p>
                </div>
              </div>
            </label>

            <label class="form-function">
              <div class="form-input-label">
                メールアドレス<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="email"
                  placeholder="メールアドレス"
                  v-model="state.email"
                  @input="state.error ? (state.error = false) : ''"
                />
              </div>
            </label>

            <label class="form-function">
              <div class="form-input-label">
                メールアドレス確認<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="email"
                  placeholder="メールアドレス確認"
                  v-model="state.emailConfig"
                  @input="state.error ? (state.error = false) : ''"
                />
                <div class="error-message" v-if="state.error">
                  <icon
                    name="error"
                    class="icon"
                  />メールアドレスの入力内容と、メールアドレス確認が一致しません
                </div>
              </div>
            </label>

            <label class="form-function items-start">
              <div class="form-input-label">
                ご相談内容<sup class="type-required">*</sup
                ><span class="form-input-label--explanation"
                  >ご利用目的やその他ご相談、ご相談内容など、ご自由にご記入ください。</span
                >
              </div>
              <div class="w-full">
                <textarea
                  rows="4"
                  cols="40"
                  maxlength="300"
                  placeholder="ご相談内容"
                  v-model="state.content"
                ></textarea>
              </div>
            </label>
          </form>
          <div id="hubspotForm" v-once></div>
        </section>
        <div
          class="error-message"
          v-for="(error, index) in state.errorMessage"
          :key="index"
        >
          <icon name="error" class="icon" />{{ error }}
        </div>
        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--save"
            :disabled="
              state.companyName == '' ||
              state.name == '' ||
              state.industriesValue == '' ||
              state.account == '' ||
              state.email == '' ||
              state.emailConfig == '' ||
              state.content == ''
            "
            @click="contact"
          >
            お問い合わせ
          </button>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { ElSelect } from "element-plus";
import { ElOption } from "element-plus";

export default defineComponent({
  name: "inquiryInput",

  components: {
    contentPageHeader,
    CommonPageTitle,
    CommonBlockTitle,
    PanelBody,
    ElSelect,
    ElOption,
  },
  props: [],
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      companyName: "",
      name: "",
      industriesValue: "",
      account: "",
      email: "",
      emailConfig: "",
      content: "",
      error: false,
      errorMessage: [],

      industries: [
        {
          value: "1",
          label: "出版・印刷・広告",
        },
        {
          value: "2",
          label: "インターネットサービス・ゲーム・アプリ",
        },
        {
          value: "3",
          label: "メーカー",
        },
        {
          value: "4",
          label: "人材サービス・教育",
        },
        {
          value: "5",
          label: "商社・流通",
        },
        {
          value: "6",
          label: "金融・保険",
        },
        {
          value: "7",
          label: "建設・不動産",
        },
        {
          value: "8",
          label: "飲食",
        },
        {
          value: "9",
          label: "旅行・宿泊・レジャー",
        },
        {
          value: "10",
          label: "コンサルティング",
        },
        {
          value: "11",
          label: "運輸・物流",
        },
        {
          value: "12",
          label: "IT・通信",
        },
        {
          value: "13",
          label: "公社・官公庁・学校",
        },
        {
          value: "14",
          label: "医療系サービス",
        },
        {
          value: "15",
          label: "その他",
        },
      ],
    });
    const isEnterprise = computed(() => store.state.user.isEnterprise);

    window.scrollTo(0, 0);

    const contact = () => {
      state.errorMessage = [];
      if (state.email === state.emailConfig) {
        const body = {
          fields: [
            {
              name: "company",
              value: state.companyName,
            },
            {
              name: "full_name",
              value: state.name,
            },
            {
              name: "industry",
              value: state.industriesValue,
            },
            {
              name: "kibou_account",
              value: state.account,
            },
            {
              name: "email",
              value: state.email,
            },
            {
              name: "message",
              value: state.content,
            },
          ],
          context: {
            pageUri: "https://jissen.me/inquiry.php",
            pageName: "ジッセン!DX お問い合わせ",
          },
        };
        console.log(body);
        axios
          .post(
            "https://api.hsforms.com/submissions/v3/integration/submit/8942317/f6b3432a-9432-4de7-92d0-6ae52d046663",
            body
          )
          .then(() => {
            router.push("/inquiry/business/input/complete");
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            error.response.data.errors.forEach((el) => {
              state.errorMessage.push(
                el.errorType === "INVALID_EMAIL"
                  ? "メールアドレスが無効です。"
                  : el.errorType === "INVALID_EMAIL"
                  ? "メールアドレスが無効です。"
                  : el.errorType === "REQUIRED_FIELD"
                  ? "必須項目を入力してください。"
                  : el.errorType === "INPUT_TOO_LARGE"
                  ? "入力可能文字数を超えています。"
                  : ""
              );
            });
          });
      }
    };

    onMounted(async () => {
      if (isEnterprise.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7710956",
          formId: "ca8e0c4f-931b-4cac-99ac-f05bd7dc8f00",
          target: "#hubspotForm"
        })
      }
    })

    return {
      store,
      state,
      contact,
    };
  },
});
</script>

<style lang="scss" scoped>
.business-inquiry {
  &::v-deep .layout-bg {
    display: none;
  }
}
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-header {
  position: relative;
  &:before {
    content: "";
    width: 584px;
    height: 584px;
    position: absolute;
    top: 70%;
    right: -289px;
    border-radius: 50%;
    background: $primary;
    opacity: 0.8;
    z-index: -1;
    @include mq(sm) {
      width: 255px;
      height: 255px;
      right: -127px;
      top: -127px;
    }
  }
}

.description {
  margin-top: 65px;
  line-height: 1.7;
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
  .out-side-content-body-header {
    margin-top: 65px;
    margin-bottom: 65px;
    margin-left: 65px;
    @include mq(sm) {
      margin-bottom: 30px;
      margin-left: 30px;
    }
  }
}
.out-side-content-body {
  margin-top: 65px;
}
.corp-bnner-block {
  .layout-bg {
    display: block;
    position: absolute;
    width: 620px;
    height: 620px;
    bottom: -310px;
    left: -310px;
    z-index: -1;
    @include mq(sm) {
      width: 310px;
      height: 310px;
      bottom: -155px;
      left: -155px;
    }
  }
}
.corp-bnner-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.corp-bnner-list-item {
  width: calc((100% - 150px) / 4);
  margin-right: 50px;
  @include mq(sm) {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;
  }
  img {
    width: 100%;
  }
  &:nth-of-type(2n) {
    @include mq(sm) {
      margin-right: 0;
    }
  }
  &:nth-of-type(4n) {
    margin-right: 0;
  }
  &:nth-of-type(n + 3) {
    @include mq(sm) {
      margin-top: 20px;
    }
  }
  &:nth-of-type(n + 5) {
    margin-top: 30px;
    @include mq(sm) {
      margin-top: 20px;
    }
  }
}
.inquiry-content {
  position: relative;
}

.inquiry-form {
  padding-top: 70px;
  padding-bottom: 65px;
  border-bottom: 0.5px solid #0f0f11;
  display: none;
}
.form-input-label {
  &--account {
    margin-top: -3em;
    @include mq(sm) {
      margin-top: 0;
    }
  }
}
.inquiry-reason-list-item {
  &:nth-of-type(n + 2) {
    margin-top: 1em;
  }
}
.submit-area {
  display: none;
}
</style>
